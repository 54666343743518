import Header from "./Header/header";
import AboutUs from "./AboutUs/AboutUs";
import People from "./People/People";
import Footer from "./Footer/footer";
import Logo from "./Logo/Logo";
// import NextEvent from "./NextEvent/NextEvent";
// import EventCard from "../../components/EventCard"

// import Image from "../../assets/event-advertisement/movie-night-1.png"
// import Image2 from "../../assets/event-advertisement/movie-night-2.png"
// import Image3 from "../../assets/event-advertisement/movie-night-1.png"

import "./HomePage.css";

// const eventData = {
//     name: "Movie Night: Interstellar",
//     date: "20/03/2023",
//     description: "Come join us for a chill movie night watching Interstellar. We will be providing snacks and drinks. Feel free to bring your own snacks too!",
//     image: [Image, Image2, Image3]
// };

function HomePage() {
    return (
        <div className={"home_page"}>
            <Header />
            <Logo/>
            {/*<EventCard event={eventData}></EventCard>*/}
            {/*<NextEvent/>*/}
            <AboutUs />
            <People />
            <Footer />
        </div>
    );
}

export default HomePage