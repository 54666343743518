import React from 'react';
import {EventScrollDiv} from "./EventScroller/EventScrollerV2";
import EventCard from "../../components/EventCard"
// import { image3,image4,image7,image8,image11,image12,image13} from '../../assets/event-advertisement/index'
// import PeriodicPCList from "../../assets/event-pictures/PeriodicPC";

import './EventScroller/EventScrollerV2.scss';
// import './EventScoller/EventScroller/EventScroller.js'
import './EventsPage.scss';
import {useEventData} from "../../components/useEventData";

function EventsPage() {
    const {upcomingProjects, pastProjects} = useEventData(false);

    return (
        <div className={"EventsPage page"}>
            {upcomingProjects.length>0 && (<div className={"headerBox"}>
                <h1>Upcoming</h1>
            </div>)}
                <EventScrollDiv>
                    {upcomingProjects.map((project) => {
                            let event = {
                                image: project.imgUrlsHigh,
                                name: project.title,
                                date: project.startTime,
                                description: project.description
                            }
                            return <EventCard event={event} />;
                    })}
                </EventScrollDiv>
            {pastProjects.length>0 && (<div className={"headerBox"}>
                <h1>Previous</h1>
            </div>)}
            <EventScrollDiv>
                {pastProjects.map((project) => {
                    let event = {
                        image: project.imgUrlsHigh,
                        name: project.title,
                        date: project.startTime,
                        description: project.description
                    }
                    return <EventCard event={event} />;
                })}
                {/*<EventCard enableBar={false} imgUrl1={image11} imgUrl2={image11} ></EventCard>*/}
                {/*<EventCard imgUrl1={image7} imgUrl2={image8} />*/}
                {/*<EventCard imgUrl1={image3} imgUrl2={image4} imgList={PeriodicPCList} style={{objectFit: "cover"}}/>*/}
            </EventScrollDiv>
            {/*<EventScroller titleText={"Past"} arrayOfEvents={pastProjects}/>*/}
        </div>
    );
}

export default EventsPage