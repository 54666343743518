import React from 'react';
// import {image5,image6} from '../../assets/event-advertisement/';
import LectureInfo from "./LectureInfo";

import './Lectures.scss';

function Lectures() {
    // const [showDetails, setShowDetails] = useState(false);

    // const toggleDetails = () => {
    //     setShowDetails(!showDetails);
    // };

    return (
        <div className="guest-lectures">
            <div className={"eventScrollDiv"}>
                <div className={"headerBox"}>
                    <h1>Lectures</h1>
                </div>
            </div>
            <LectureInfo/>
        </div>
    );
}

export default Lectures