import React, {useEffect, useLayoutEffect, useRef, useState} from 'react' ;

import './ImageCarousel.scss';
import CarouselItem from "./CarouselItem";
import {useStateRef, getRefValue} from "../../hooks/hooks";

function ImageCarousel(props) {
    const containerRef = useRef(null);
    const images = props.images;
    const currentOffsetXRef = useRef(0);
    let containerWidthRef = useRef(0);
    const startXRef = useRef(0);
    const minOffsetXRef = useRef(0);
    const [offsetX, setOffsetX, offsetXRef] = useStateRef(0);
    const [isSwiping, setIsSwiping] = useState(false);
    const [currentIdx, setCurrentIdx] = useStateRef(props.index);
    useEffect(() => {
        setCurrentIdx(props.index);
        const containerEl = containerRef.current;
        if (containerEl) {
            const containerWidth = containerEl.offsetWidth;
            setOffsetX(-props.index * containerWidth);
        }
    }, [props.index, setCurrentIdx, setOffsetX]);
    console.log(props.index)
    console.log(currentIdx);
    const MIN_SWIPE_DISTANCE = 40;

    if (offsetX) console.log(offsetX);

    useLayoutEffect(() => {
        const containerEl = containerRef.current;
        if (containerEl) {
            containerWidthRef.current = containerEl.offsetWidth;
        }
    }, [images]);


    const onMouseMove = (e) => {
        console.log("Mouse Move")
        const currentX = e.clientX;
        const diff = getRefValue(startXRef) - currentX;
        let newOffsetX = getRefValue(currentOffsetXRef) - diff;

        const maxOffsetX = 0;
        const minOffsetX = getRefValue(minOffsetXRef);

        if(newOffsetX > maxOffsetX) {
            newOffsetX = maxOffsetX;
        }

        if(newOffsetX < minOffsetX) {
            newOffsetX = minOffsetX;
        }

        setOffsetX(newOffsetX);
    };

    const onMouseUp = () => {
        console.log("Mouse Up")
        const currentOffsetX = getRefValue(currentOffsetXRef);
        const containerWidth = getRefValue(containerWidthRef);
        if (containerWidth === 0) {
            return;
        }
        let newOffsetX = getRefValue(offsetXRef);

        const diff = currentOffsetX - newOffsetX;

        if(Math.abs(diff) > MIN_SWIPE_DISTANCE) {
            if (diff > 0) {
                newOffsetX = Math.floor(newOffsetX / containerWidth) * containerWidth;
            } else {
                newOffsetX = Math.ceil(newOffsetX / containerWidth) * containerWidth;
            }
        } else {
            newOffsetX = Math.round(newOffsetX / containerWidth) * containerWidth;
        }


        setIsSwiping(false);
        setOffsetX(newOffsetX);
        setCurrentIdx(Math.abs(newOffsetX / containerWidth));

        window.removeEventListener('mousemove', onMouseMove);
        window.removeEventListener('mouseup', onMouseUp);
    };

    const onTouchMove = (e) => {
        console.log("Touch Move")
        const currentX = e.touches[0].clientX;
        const diff = getRefValue(startXRef) - currentX;
        let newOffsetX = getRefValue(currentOffsetXRef) - diff;

        const maxOffsetX = 0;
        const minOffsetX = getRefValue(minOffsetXRef);

        if(newOffsetX > maxOffsetX) {
            newOffsetX = maxOffsetX;
        }

        if(newOffsetX < minOffsetX) {
            newOffsetX = minOffsetX;
        }


        setOffsetX(newOffsetX);
    }

    const onTouchEnd = () => {
        console.log("Touch End")
        const currentOffsetX = getRefValue(currentOffsetXRef);
        const containerWidth = getRefValue(containerWidthRef);
        if(containerWidth === 0) {
            return;
        }
        let newOffsetX = getRefValue(offsetXRef);

        const diff = currentOffsetX - newOffsetX;

        if(Math.abs(diff) > MIN_SWIPE_DISTANCE) {
            if (diff > 0) {
                newOffsetX = Math.floor(newOffsetX / containerWidth) * containerWidth;
            } else {
                newOffsetX = Math.ceil(newOffsetX / containerWidth) * containerWidth;
            }
        } else {
            newOffsetX = Math.round(newOffsetX / containerWidth) * containerWidth;
        }

        setIsSwiping(false);
        setOffsetX(newOffsetX);
        setCurrentIdx(Math.abs(newOffsetX / containerWidth));

        window.removeEventListener('touchmove', onTouchMove);
        window.removeEventListener('touchend', onTouchEnd)
    };

    const onTouchStart = (e) => {
        console.log("Touch Start")
        currentOffsetXRef.current = getRefValue(offsetXRef);
        startXRef.current = e.touches[0].clientX;

        const containerEl = getRefValue(containerRef);

        minOffsetXRef.current = containerEl.offsetWidth - containerEl.scrollWidth;
        setIsSwiping(true);

        window.addEventListener('touchmove', onTouchMove);
        window.addEventListener('touchend', onTouchEnd);
    }

    const onMouseDown = (e) => {
        console.log("Mouse Down")
        currentOffsetXRef.current = getRefValue(offsetXRef);
        startXRef.current = e.clientX;

        const containerEl = getRefValue(containerRef);

        minOffsetXRef.current = containerEl.offsetWidth - containerEl.scrollWidth;
        setIsSwiping(true);

        window.addEventListener('mousemove', onMouseMove);
        window.addEventListener('mouseup', onMouseUp);
    };

    const indicatorOnClick = (idx) => {
        const containerEl = getRefValue(containerRef);
        const containerWidth = containerEl.offsetWidth;
        console.log("Indicator Clicked");

        setCurrentIdx(idx);
        setOffsetX(-idx * containerWidth);
    }

    return(
        <div className={"image-carousel"} onMouseDown={onMouseDown} onTouchStart={onTouchStart}>

            <ul
                ref={containerRef}
                className={`image-carousel-list ${isSwiping ? 'is-swiping' : ''}`}
                style={{transform: `translateX(${offsetXRef.current}px)`}}
            >
                {images.map((image, index) => {
                    return(<CarouselItem imageSrc={image} imageAlt={index}/>)
                })}
            </ul>
            <ul className={"image-carousel-indicator"}>
                {images.map((item, idx) => {
                    return(<li key={idx} className={`image-carousel-indicator-item ${idx === currentIdx ? 'active' : ''}`} onClick={(e) => {e.stopPropagation(); indicatorOnClick(idx)}}></li>)
                })}
            </ul>
        </div>
    )
}

export default ImageCarousel;