import imageUrlBuilder from '@sanity/image-url';

const PROJECT_ID = "59scku0j";
const DATASET = "production";
const TYPE = "event";
const ORDER = "startTime desc";
const START = 0;
const END = 100;
const builder = imageUrlBuilder({ projectId: PROJECT_ID, dataset: DATASET });


export function buildSanityQuery(){
    return encodeURIComponent(`*[_type == "${TYPE}"] | order(${ORDER}) [${START}...${END}]`);
}

/**
 * Get the Backend data from sanity
 */
export function getSanityData(){
    if(localStorage.getItem("sanityData")){
        console.log("Using locally cached data")
        const sanityData = JSON.parse(localStorage.getItem("sanityData"));
        if(sanityData && sanityData.data && sanityData.timestamp) {
            const now = new Date();
            const timeSinceLastFetch = now - new Date(sanityData.timestamp);
            const minutesSinceLastFetch = Math.floor(timeSinceLastFetch / 1000 / 60);

            if (minutesSinceLastFetch < 60) {
                return sanityData.data;
            }
        }
        console.log("Locally cached data is too old");
    }

    console.log("Fetching data from CMS");
    const QUERY = buildSanityQuery();
    const URL = `https://${PROJECT_ID}.api.sanity.io/v2021-10-21/data/query/${DATASET}?query=${QUERY}`;

    return fetch(URL)
        .then((res) => res.json())
        .then(({ result }) => {
            console.log(result);

            if (result.length > 0) {
                const now = new Date(); // Get current date and time

                const fetchedData = result.map((data) => {
                    // Process each image in the imgUrl array
                    const imgUrlsLow = data.images?.map(img =>
                        builder.quality(0).auto('format').height(40).image(img.asset?._ref).url()
                    );

                    const imgUrlsHigh = data.images?.map(img =>
                        builder.quality(80).auto('format').height(400).image(img.asset?._ref).url()
                    );
                    console.log("imgUrlsHigh:", imgUrlsHigh);
                    console.log("Logging Lecture Value:", data?.isLecture);

                    return {
                        isLecture: data?.lecture,
                        name: data?.title,
                        imgUrlsLow, // Array of low-quality image URLs
                        imgUrlsHigh, // Array of high-quality image URLs
                        ticketUrl: data?.ticketUrl,
                        summary: data?.summary,
                        description: data?.description,
                        genres: data?.genres,
                        startTime: data?.startTime,
                        endTime: data?.endTime,
                        location: data?.location,
                        locationUrl: data?.locationUrl,
                        key: data._id,
                    };
                });


                localStorage.setItem("sanityData", JSON.stringify({data: fetchedData, timestamp: now}));

                return fetchedData;
            }
        })
        .catch((err) => console.error(err));
}


export async function getClosestEvent() {
    const now = new Date();
    const fetchedProjects = await getSanityData();
    if (!fetchedProjects) {
        return;
    }

    const upcomingProjectsData = fetchedProjects.filter((project) => {
        const eventDate = new Date(project.startTime);
        return eventDate >= now;
    });

    const pastProjectsData = fetchedProjects.filter((project) => {
        const eventDate = new Date(project.startTime);
        return eventDate < now;
    });

    if (upcomingProjectsData.length > 0) {
        upcomingProjectsData.sort((a, b) => {
            const dateA = new Date(a.startTime);
            const dateB = new Date(b.startTime);
            return dateA - dateB;
        });

        return upcomingProjectsData[0];
    }

    if (pastProjectsData.length > 0) {
        pastProjectsData.sort((a, b) => {
            const dateA = new Date(a.startTime);
            const dateB = new Date(b.startTime);
            return dateB - dateA;
        });
        return pastProjectsData[0];
    }

    return null;
}
