import './AboutPage.scss';
import React from "react";
// import Collapsible, {CollapsibleGroup} from "../../components/collapsible/Collapsible";
// import {NavItem} from "../../components";
import {AiOutlineInstagram, AiOutlineWhatsApp} from "react-icons/ai";
/**
 * The contents of the About Us page
 * @returns {JSX.Element}
 * @constructor
 */
function AboutPage() {
    let about_content = [
        ["Who are we?", "We are Natural Sciences Society Southampton University (NATSSU). We were founded on the 1st of February 2023 and are student-run society, focused on promoting interdisciplinary science and STEM."],
        ["Who can attend?", "Anyone and everyone! Our events are designed so you can enjoy them regardless of what discipline you are from, scientific or not."],
        ["Why do we exist?", "We believe science is interdisciplinary from start to finish. Promoting this outlook on STEM is a core principle of the society, alongside getting more people involved with science. They may not be from the exact discipline in mention or might not even study a STEM degree at all, yet we still think there is something for everyone."],
        ["How do we achieve our goals?", "By hosting socials, student-led lectures, and guest lectures, we can incentivise students to take more of an interest in interdisciplinary science or disciplines they have never been involved with."],
        ["What is the point of the guest lectures?", "We host these lectures with a different guest speaker every time. The subject topic can range from particle physics to environmental science. We also utilise a lecture-specific booklet system. To ensure anyone from any area of academia can take an interest in our lectures, we have specific booklets with key words and diagrams to aid understanding of the lectures. There are a small number of them in person, and are available digitally under the lectures tab."],
        ["What’s next? And how to join?", "We are currently working towards university affiliation with University of Southampton. We intend to host more of our signature events and intend to hold some academic workshops in the future as well! To become a member, join the WhatsApp groupchat or follow us on Instagram for future updates."]
    ];
    return (
        <>
            <div className={"about-page"}>
                <div  className={"CollapsibleGroup about-text"}>
                        {
                            about_content.map((item) => {
                                return (
                                    <>
                                        <h2>{item[0]}</h2>
                                        <p>{item[1]}</p>
                                    </>
                                );
                            })
                        }
                    <div className={"about-links"}>
                        <a href={"https://www.instagram.com/natsocsoton/"}>{<AiOutlineInstagram/>}</a>
                        <a href={"https://chat.whatsapp.com/BwC2qMlr8sGLesWaffvK86"}>{<AiOutlineWhatsApp/>}</a>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AboutPage;