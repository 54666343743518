import React from 'react';

import './CarouselItem.scss';

function CarouselItem(props) {
    const imageSrc = props.imageSrc;
    const imageAlt = props.imageAlt;
    // const img = props.img;
    return(
        <li className={"carousel-item"}>
            <img src={imageSrc} alt={imageAlt} className={"carousel-image"} draggable={false}/>
        </li>
    )
}

export default CarouselItem;