import "./Links.scss";
import {
    AiOutlineInstagram,
    // AiOutlineFacebook,
    // AiOutlineTwitter,
    // AiOutlineMail,
    AiOutlineWhatsApp
} from "react-icons/ai";

/**
 * Links component
 * @returns {JSX.Element}
 * @constructor
 */
const Links = () => {

    return(
        <div className={"links"}>
            <a href={"https://www.instagram.com/natscisoton/"}><AiOutlineInstagram></AiOutlineInstagram></a>
            <a href={"https://chat.whatsapp.com/BwC2qMlr8sGLesWaffvK86"}><AiOutlineWhatsApp/></a>
        </div>
    )
}

export default Links;