import { useRef, useState } from 'react';

export function getRefValue(ref){
    return ref.current;
}

export function useStateRef(initialValue) {
    const ref = useRef(initialValue);
    const [state, _setState] = useState(initialValue);
    const setState = (value) => {
        _setState(value);
        ref.current = value;
    };

    return [state, setState, ref];
}