import './App.scss';
import React from 'react';
import HomePage from './Container/HomePage' // Imports All Containers
import {Navbar,NavItem,DropdownItem} from "./components";
import "./App.scss";
import {BrowserRouter as Router, Routes, Route,} from 'react-router-dom';
import EventsPage from "./Container/EventsPage";
import {NatssuLogoText} from "./assets/logo";
import {AiOutlineInstagram, AiOutlineMenuFold, AiOutlineWhatsApp} from "react-icons/ai";
import Links from "./Container/HomePage/Links/Links";
import Lectures from "./Container/Lectures";
import AboutPage from "./Container/AboutUs";

/**
 * App Component
 * @returns {JSX.Element}
 * @constructor
 */
function App() {
    // const ref = React.useRef(null);
    const [offset, setOffset] = React.useState(0);
    // const [largeChange, setLargeChange] = React.useState(false);

    React.useEffect(() => {
        let handleScroll;

        handleScroll = () => {
            const newOffset = window.pageYOffset - 650;
            // setLargeChange(Math.abs(newOffset - offset) > 50); // Adjust the threshold as needed
            setOffset(newOffset);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [offset]);

    const home = window.location.pathname === "/";
    const events = window.location.pathname === "/Events";
    const lectures = window.location.pathname === "/Lectures";
    const about = window.location.pathname === "/About";
    // const about = window.location.pathname === "/About";

    const [width, setWidth] = React.useState(window.innerWidth);
    const breakpoint = 620;

    React.useEffect(() => {
        /* Inside of a "useEffect" hook add an event listener that updates
           the "width" state variable when the window size changes */
        window.addEventListener("resize", () => setWidth(window.innerWidth));

        /* passing an empty array as the dependencies of the effect will cause this
           effect to only run when the component mounts, and not each time it updates.
           We only want the listener to be added once */
    }, []);
  return (
    <main className={"main"} >
        <Navbar left={(width > breakpoint) &&
            <>
                <a href={"/"} className={home && "selected"}>Home</a>
                <a href={"/Lectures"} className={lectures && "selected"}>Lectures</a>
                <a href={"/Events"} className={events && "selected"}>Events</a>
                <a href={"/About"} className={about && "selected"}>About Us</a>
            </>
        } href={"/"} >

            { (width > breakpoint) ? <>
                    <NavItem href={"https://www.instagram.com/natsocsoton/"} icon={<AiOutlineInstagram/>}/>
                    <NavItem href={"https://chat.whatsapp.com/BwC2qMlr8sGLesWaffvK86"} icon={<AiOutlineWhatsApp/>}/>
                    </>
                : <NavItem href={"#"} icon={<AiOutlineMenuFold/>}>
                    <DropdownItem ><img src={NatssuLogoText} alt={"NatSoc Logo"}/></DropdownItem>
                    <DropdownItem href={"/"} childClass={home && "selected"}><h1>Home</h1></DropdownItem>
                    <DropdownItem href={"/Lectures"} childClass={lectures && "selected"}><h1>Lectures</h1></DropdownItem>
                    <DropdownItem href={"/Events"} childClass={events && "selected"}><h1>Events</h1></DropdownItem>
                    <DropdownItem href={"/About"} childClass={about && "selected"}><h1>About Us</h1></DropdownItem>
                    {/*<DropdownItem href={"/about-us"}><h1>About Us</h1></DropdownItem>*/}
                    <DropdownItem>
                        <Links/>
                    </DropdownItem>
                </NavItem> }
        </Navbar>
        {/*<div ref={ref} style={{transform: `translateY(${offset*0.1}px)` }} id={"stars"}/>*/}
        {/*<div style={{transform: `translateY(${offset*0.2}px)` }} id={"stars2"}/>*/}
        {/*<div style={{transform: `translateY(${offset*0.3}px)` }} id={"stars3"}/>*/}
      <Router>
          <Routes>
              <Route path={"/"} element={<HomePage/>}/>
              <Route path={"/events"} element={<EventsPage/>}/>
              <Route path={"/about"} element={<AboutPage/>}/>
              <Route path={"/lectures"} element={<Lectures/>}/>
          </Routes>
      </Router>
    </main>

  );
}

export default App;
