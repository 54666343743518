import { useState, useEffect } from 'react';
import {getSanityData} from "../SanityHandler/SanityHandler";

// Assuming getSanityData is imported or defined somewhere
// import { getSanityData } from '../path/to/your/function';

export const useEventData = (isLecture) => {
    const [pastProjects, setPastProjects] = useState([]);
    const [upcomingProjects, setUpcomingProjects] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const fetchedProjects = await getSanityData();
            if (!fetchedProjects) return;
            const now = new Date(); // Get current date and time

            const pastProjectsData = fetchedProjects.filter(project => {
                const eventDate = new Date(project.startTime);
                if(isLecture){
                    return eventDate < now && project.isLecture === true;
                } if(isLecture === false) {
                    return eventDate < now && project.isLecture !== true;
                } else {
                    return eventDate < now;
                }
            });

            const upcomingProjectsData = fetchedProjects.filter(project => {
                const eventDate = new Date(project.startTime);
                if(isLecture) {
                    return eventDate >= now && project.isLecture === true;
                } if(isLecture === false) {
                    return eventDate >= now && project.isLecture !== true;
                } else {
                    return eventDate < now;
                }
            });

            setPastProjects(pastProjectsData);
            setUpcomingProjects(upcomingProjectsData);
        };

        fetchData();
    },[isLecture]); // Empty dependency array means this effect runs once on mount

    return { pastProjects, upcomingProjects };
}

