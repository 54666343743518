import React, { useState } from 'react';
import {FaChevronUp, FaChevronLeft, FaChevronRight } from 'react-icons/fa'; // Import Chevron icons for navigation
import './EventCard.css';
import ImageCarousel from "../ImageCarousel/ImageCarousel";

const EventCard = ({ event }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);

    const images = Array.isArray(event.image) ? event.image : [event.image];


    // Adjusted toggleExpand function to be more explicit
    const toggleExpand = () => {

        console.log('toggling');
        // Check here ensures the function only toggles expansion if there's no carousel
        if (images.length < 2) {
            setIsExpanded(!isExpanded);
        }
    };

    // Separate handler for preventing event propagation from carousel navigation
    const handleNavigation = (e, direction) => {
        e.stopPropagation(); // Prevent click event from bubbling up to the card
        console.log('navigating')
        const adjustment = direction === 'left' ? -1 : 1;
        const newIndex = currentIndex + adjustment;
        setCurrentIndex(Math.max(0, Math.min(newIndex, images.length - 1)));
    };

    return (
        <div className={`event-card ${isExpanded ? 'expanded' : ''}`} onClick={images.length === 1 ? toggleExpand : undefined}>
            <div className="event-card__image-container">
                {images.length > 1 ? (
                    <>
                        <ImageCarousel images={images} index={currentIndex}/>
                        <div className="event-card__nav event-card__nav-left" onClick={(e) => {handleNavigation(e, 'left') }}><FaChevronLeft    className={'chevron_left'}/></div>
                        <div className="event-card__nav event-card__nav-right" onClick={(e) => {handleNavigation(e, 'right') }}><FaChevronRight className={'chevron_right'}/></div>

                    </>
                ) : (
                    <img
                        className="event-card__image"
                        src={event.image}
                        alt={event.name}
                    />
                )}
                <div className={`event-card__overlay ${isExpanded ? 'expanded' : ''}`}>
                    <div className="event-card__overlay-content">
                        <h3>{event.name}</h3>
                        <p>{event.date}</p>
                        <p>{event.description}</p>
                    </div>
                </div>
            </div>
            {
                images.length < 2 &&
                <span className="event-card__cta">
                    <FaChevronUp className={"event-card__chevron"} size={20} style={{ transform: isExpanded ? "rotate(0deg)" : "rotate(180deg)" }} />
                </span>
            }
        </div>
    );
};


export default EventCard;
