import "./aboutUs.scss";


/**
 * About Us component
 * @returns {JSX.Element}
 * @constructor
 */
const AboutUs = () => {
    return (
        <>
            <div className={"about_us"}>
                <h1 className={"underline_header"}>About us:</h1>
                <p>
                    Natural Sciences Society Southampton aims to bring more and more students into
                    the field of extended STEM and interdisciplinary science through interactive
                    lectures, fun social events and field trips.  By hosting interactive lectures
                    with bespoke handouts written for each specific lecture, we hope to bring in
                    students from non-STEM backgrounds into enjoying the many avenues of interdisciplinary
                    science. Each handout will have an assortment of definitions and diagrams to aid and
                    supplement the understanding of the lecture. We were founded on the 1st of February
                    2023 and are currently a work in progress. Check back soon for more updates :)
                </p>
            </div>
        </>
    );
}

export default AboutUs;