// import {react} from 'react';
import "./People.scss";

const People = () => {
    return (
        <div className="people">

        </div>
    )
}

export default People;