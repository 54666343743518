import './Logo.scss';
import React from 'react';
import NatssuLogoText from '../../../assets/logo/logo blue.png';

function Logo() {

    return(
        <div className={"logo"}>
            <img src={NatssuLogoText} alt={"logo"}/>
        </div>
    );

}

export default Logo