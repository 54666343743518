import React from 'react';
import './header.scss'


/**
 * Header component
 * @returns {JSX.Element}
 * @constructor
 */
const Header = () => {

    return (
        <div className={"header__box app__flex"}>
        </div>
    );
}



export default Header;
