import React from 'react';

import {EventScrollDiv} from "../../EventsPage/EventScroller/EventScrollerV2";
import EventCard from "../../../components/EventCard";
// import {image1, image2, image5, image6, GL28TH1, GL28TH2} from '../../../assets/event-advertisement/index'
// import gl1 from '../../../assets/documents/gl1.pdf'
import './LectureInfo.scss';
import {useEventData} from "../../../components/useEventData";
// import background from "../../../assets/BACKGR.png";

// const lectureData = [
//     // {lecTitle: "The Higgs Boson: How, What and Why", lecDesc: "Dr Andrew Akeroyd will be presenting a lecture on the Higgs-boson particle. This lecture will cover the history of the Higgs-boson, how it was discovered and why it is important.", imgUrl1: GL28TH1, imgUrl2: GL28TH2, /**lecHref: "https://southampton.cloud.panopto.eu/Panopto/Pages/Viewer.aspx?id=3b6b0b1a-4b0a-4b0a-8b0a-afcc00c3b6a4",**/ lecPres: "by Dr. Andrew Akeroyd" /**, downloadLink: gl1**/},
//     // {lecTitle: "The Sounds of a Quantum Guitar", lecDesc: "Dr Elinor Irish is going to talk about some of the wierd features of quantum physics, including research into developing 'quantum guitar strings' and their potential applications.", imgUrl1: image9, imgUrl2: image10, lecPres: "by DR. Elinor Irish"},
//     {lecTitle: "Plastics as Water Pollutants", lecDesc: "This lecture will provide further insight to the adverse implications of plastic usage in today's society, more specifically as a water pollutant.", imgUrl1: image5, imgUrl2: image6, lecHref: "https://southampton.cloud.panopto.eu/Panopto/Pages/Viewer.aspx?id=c2eea903-77ab-4fd0-8e50-afcc00c3637c", lecPres: "by Prof. Ian Williams", downloadLink: gl1}
//     ]


function LectureInfo() {
    const {upcomingProjects, pastProjects} = useEventData(true);
    return (
            <div className={"LectureInfo"}>
                <EventScrollDiv>
                    {upcomingProjects.map((project) => {
                        let event = {
                            image: project.imgUrlsHigh,
                            name: project.title,
                            date: project.startTime,
                            description: project.description
                        }
                        return <EventCard event={event} />;
                    })}
                </EventScrollDiv>
                <EventScrollDiv>
                    {pastProjects.map((project) => {
                        let event = {
                            image: project.imgUrlsHigh,
                            name: project.title,
                            date: project.startTime,
                            description: project.description
                        }
                        return <EventCard event={event}/>
                    })}
                </EventScrollDiv>
            </div>
        );
    }

export default LectureInfo