import {useState} from "react";
import {AiOutlineDownload, AiOutlineInfoCircle} from "react-icons/ai";
import './EventScrollerV2.scss'
import './LectureCard.scss'
import {
    BsArrowLeftCircle,
    BsArrowRightCircle,
} from "react-icons/bs";
import ImageCarousel from "../../../components/ImageCarousel/ImageCarousel";

function LectureCard(props){
    const [selected, setSelected] = useState(false);
    // const [picIndex, setPicIndex] = useState(0);
    let imageList;
    if(props.imgUrl1) {
        console.log("imgUrl1")
        imageList = [props.imgUrl1, props.imgUrl2].concat(props.imgList)
    } else {
        console.log("no imgUrl1")
        imageList = props.imgList;
    }
    console.log(imageList)

    function toggleSelected() {
        setSelected(!selected);
        console.log(selected);
    }

    return(
        <div className={"lectureCard"}>
            <div className={"lectureCardImg"}>
                <img src={imageList[0]} alt={props.title} style={props.style}/>
            </div>

                <div className={`lectureCardInformation ${selected && 'info-visible'}`}>
                    { selected && <>
                    <p className={"lectureTitle"}>{props.lecTitle}</p>
                    <p className={"lecturePresentor"}>{props.lecPresentor}</p>
                    <p className={"lectureDescription"}>{props.lecDesc}</p>
                    {props.downloadLink && <a className={"lectureDownload"} download={props.downloadLink} href={props.downloadLink}>Lecture Notes     <AiOutlineDownload/></a>}
                    {props.href && <a className={"lectureLink"} href={props.href}>Watch the lecture!</a>}
                    </>}
                </div>
            <div className={"lectureCardContent"}>
                <AiOutlineInfoCircle className={`arrowButton eventCardInfo ${selected && 'selected'}`} onClick={toggleSelected}/>
                {props.href && <div className={"signUpButton"}>
                    {/*<a href={props.href}>Sign up here!</a>*/}
                </div>}
            </div>
        </div>
    );
}

function EventScrollDiv(props) {

    return(
        <div className={"eventScrollDiv"}>
            {props.children}
        </div>
    );
}


// function EventCard(props) {
//     const [selected, setSelected] = useState(false);
//     const [expanded, setExpanded] = useState(false); // new state for managing expansion
//     const [picIndex, setPicIndex] = useState(0);
//     let imageList = [props.imgUrl1, props.imgUrl2].concat(props.imgList);
//
//     function toggleSelected() {
//         setSelected(!selected);
//     }
//
//     function toggleExpand() {
//         setExpanded(!expanded); // toggles the expanded view
//     }
//
//     function handleCardClick() {
//         toggleExpand();
//     }
//
//     function incrIndex() {
//         if(picIndex < imageList.length - 1) {
//             setPicIndex(picIndex + 1);
//         } else {
//             setPicIndex(0);
//         }
//     }
//
//     function decrIndex() {
//         if(picIndex > 0) {
//             setPicIndex(picIndex - 1);
//         } else {
//             setPicIndex(imageList.length - 1);
//         }
//     }
//
//     return(
//         <div className={"eventCard" + (expanded ? " expanded" : "")} onClick={handleCardClick}>
//             { (props.imgUrl1 && props.imgUrl2) &&
//                 <div className={"eventCardImg"}>
//                     {!(props.imgList) ? (selected ?
//                             <img src={imageList[1]} alt={props.title} style={props.style}/> :
//                             <img src={imageList[0]} alt={props.title} style={props.style}/>
//                     ) : <ImageCarousel images={imageList}/> }
//
//
//                 </div>}
//             { (props.title || props.date || props.time || props.location) &&
//                 <div className={"eventText"}>
//                     {props.title && <h2>{props.title}</h2>}
//                     {props.date && <p>Date: {props.date}</p>}
//                     {props.time && <p>Time: {props.time}</p>}
//                     {props.location && <p>Location: {props.location}</p>}
//                 </div>
//             }
//             {props.enableBar !== false && <div className={"eventCardContent"}>
//                 {props.imgList ? <>
//                     <button className={"arrowButton leftButton"} onClick={decrIndex}><BsArrowLeftCircle/></button>
//                     <button className={"arrowButton rightButton"} onClick={incrIndex}><BsArrowRightCircle/></button>
//                 </> : <>
//                     <AiOutlineInfoCircle className={"arrowButton eventCardInfo"} onClick={toggleSelected}/>
//                     {props.href && <div className={"signUpButton"}>
//                         <a href={props.href}>Sign up here!</a>
//                     </div>} </>}
//             </div>}
//         </div>
//     );
// }

function EventCard(props) {
    const [selected, setSelected] = useState(false);
    const [picIndex, setPicIndex] = useState(0);

    let imageList;
    console.log("props.imgList: " + props.imgList);
    if(props.imgUrl1) {
        console.log("imgUrl1")
        if(props.imgList) {
            imageList = [props.imgUrl1, props.imgUrl2].concat(props.imgList)
        } else {
            imageList = [props.imgUrl1, props.imgUrl2];
        }
    } else {
        console.log("no imgUrl1")
        imageList = props.imgList;
    }
    console.log("list: " + imageList);

    function toggleSelected() {
        setSelected(!selected);
        console.log(selected);
    }

    function incrIndex() {
        if(picIndex < imageList.length - 1) {
            setPicIndex(picIndex + 1);
        } else {
            setPicIndex(0);
        }
        console.log("picIndex: " + picIndex);
    }

    function decrIndex() {
        if(picIndex > 0) {
            setPicIndex(picIndex - 1);
        } else {
            setPicIndex(imageList.length - 1);
        }
        console.log("picIndex: " + picIndex);
    }


    return(
        <div className={"eventCard"}>
            { (imageList) &&
                <div className={"eventCardImg"}>
                    {(imageList.length<=2) ? (selected ?
                            <img src={imageList[1]} alt={props.title} style={props.style}/> :
                            <img src={imageList[0]} alt={props.title} style={props.style}/>
                    ) : <ImageCarousel images={imageList} index={picIndex}/> }


                </div>}
            {/*{ (props.title || props.date || props.time || props.location) &&*/}
            {/*    <div className={"eventText"}>*/}
            {/*        {props.title && <h2>{props.title}</h2>}*/}
            {/*        {props.date && <p>Date: {props.date}</p>}*/}
            {/*        {props.time && <p>Time: {props.time}</p>}*/}
            {/*        {props.location && <p>Location: {props.location}</p>}*/}
            {/*    </div>*/}
            {/*}*/}
            {props.enableBar !== false && <div className={"eventCardContent"}>
                {(imageList && imageList.length>2) ? <>
                    <button className={"arrowButton leftButton"} onClick={decrIndex}><BsArrowLeftCircle/></button>
                    <button className={"arrowButton rightButton"} onClick={incrIndex}><BsArrowRightCircle/></button>
                </> : <>
                    <AiOutlineInfoCircle className={"arrowButton eventCardInfo"} onClick={toggleSelected}/>
                    {props.href && <div className={"signUpButton"}>
                        <a href={props.href}>Sign up here!</a>
                    </div>} </>}
            </div>}
        </div>
    );
}

export {EventCard, EventScrollDiv,LectureCard};