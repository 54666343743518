import React, {useState} from 'react';
import './NavBar.scss';
// import logo from '../../assets/NatSciLogo.png';
// import {AiOutlineInstagram, AiOutlineFacebook,AiOutlineTwitter,AiOutlineMail,AiOutlineMenu} from "react-icons/ai";

/**
 * dropdown Menu component for the navbar
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
// const Menu = ({ children }) => {
//     //uses a boolean value to set the dropdown menu to open or closed
//     const [isOpen, setIsOpen] = useState(false);
//
//     //gets the first element of the children array and the rest of the elements
//     const [head, ...tail] = children;
//
//     //toggles the dropdown menu
//     const toggleOpen = () => {
//         setIsOpen(!isOpen);
//     }
//     return (
//         <div className="menu" onClick={() => toggleOpen()}>
//             {head}
//             {isOpen && <div className={"open"}>{tail}</div>}
//         </div>
//     );
// }

/**
 * NavBar component
 * @returns {JSX.Element}
 * @constructor
 */
// const NavBar = () => {
//     const firstChildStyle = {marginTop: "2vh"} //style for the first child of the menu
//     return (
//         <div className={"navbar app__flex"}>
//             <div className={"NatSciLogo"}><img src={logo} alt={"Natural Sciences Society Logo"}/></div>
//             <Menu>
//                 <AiOutlineMenu className={"MenuButton"}></AiOutlineMenu>
//                 <a href={"#body"}><AiOutlineFacebook style={firstChildStyle}></AiOutlineFacebook></a>
//                 <a href={"https://www.instagram.com/natscisoton/"}><AiOutlineInstagram></AiOutlineInstagram></a>
//                 <a href={"#body"}><AiOutlineTwitter></AiOutlineTwitter></a>
//                 <a href={"#body"}><AiOutlineMail></AiOutlineMail></a>
//             </Menu>
//         </div>
//     );
// }

function Navbar(props){
    return(
        <nav className={"navbar"}>
            <div className={"Logo"}><a href={props.href}>{ props.left }</a></div>
            {/*<div className={"middle-logo"}>{props.logo}</div>*/}
            <ul className={"navbar-nav"}>
                { props.children }
            </ul>
        </nav>

    )
}

function NavItem(props){
    const [open, setOpen] = useState(false);

    return(
        <li className={"nav-item"}>
            <a href={props.href} className={`icon-button ${open ? 'icon-open' : ''}`} onClick={() => setOpen(!open)}>
                {props.icon}
            </a>
            <div className={open ? "dropdown" : "dropdown-invisible"}>
                {props.children}
            </div>
        </li>
    )
}

function DropdownItem(props) {
    return (
        <a href={props.href} className={"menu-item " + props.childClass}>
            {/*<span className="icon-left icon-button">{props.leftIcon}</span>*/}
            {props.children}
        </a>
    );
}


export {Navbar,NavItem,DropdownItem};